import { fetchStudentBatchesDetails } from "api/onboarding";
import { ApiResponse } from "apisauce";
import { cast, flow, types } from 'mobx-state-tree';
import User from './User';
import { notify } from '../components/styledComponents/Notification';


const Course = types.model('Course', {
  name: types.string,
  title: types.string,
  subject: types.string,
});

const Batch = types.model('Batch', {
  name: types.string,
  zoho_name: types.string,
  coach: types.maybe(User),
  timing_based_name: types.string,
  course: Course
});

const StudentBatches = types.model('CoachInsightCallStats', {
  name: types.string,
  student: types.maybe(User),
  batch: types.maybe(Batch),
  last_call_conducted_days_ago: types.maybeNull(types.number),
  coach: types.maybe(User),
  status: types.string,
  status_updated_on: types.string,
});


const StudentStore = types.model('StudentStore', {
    loading: false,
    studentBatches: types.optional(types.array(StudentBatches), []),
  })
  .actions((self) => ({
    fetchStudentBatchesDetails: flow(function* (username: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchStudentBatchesDetails(username);
        if (response.problem) {
          if (response.status === 404) {
            notify.error("No active batch found.");
          } else {
            notify.error("Something went wrong.");
          }
          throw response;
        }
        if (response.status === 200) {
          self.studentBatches = cast(response.data);
        }
      } finally {
        self.loading = false;
      }
    }),
}));

export default StudentStore;