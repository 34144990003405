import {
  guestLogin,
  phoneExists,
  postOtp,
  signup,
  signupOtp,
  createGuestAccount,
  pre_validate
} from 'api';
import { ApiResponse } from 'apisauce';
import { flow, Instance, types, getEnv } from 'mobx-state-tree';
import { IUser } from './User';

const SERVER_ERROR_MESSAGE =
  "Oops! there's some issue at our end, Please try again afer some time.";
const PHONE_REGEX_INDIA = /^(\+?91|0)[6789]\d{9}$/;

const SignUpStore = types
  .model('SignUpStore', {
    loading: false,
    errors: types.map(types.array(types.string)),
    otherErrors: '',
    otpError: '',
    parentName: types.optional(types.string, ''),
    parentEmail: types.optional(types.string, ''),
    phone: types.optional(types.string, ''),
    kidName: types.optional(types.string, ''),
    kidSchool: types.maybe(types.string),
    kidGrade: types.optional(types.string, ''),
    signupSuccess: false,
    extraInfo: types.map(types.string),
    hasLaptop: types.maybe(types.boolean),
    username: types.optional(types.string, ''),
    otp: types.optional(types.string, ''),
    guestAccountCreated: false,
    guestUsername: types.maybeNull(types.string),
    existingStudentName: types.maybeNull(types.string)
  })
  .actions((self) => ({
    setErrors(errors: { [key: string]: Array<string> }) {
      self.errors.merge(errors);
    },
    setOTPError(err: string) {
      self.otpError = err;
    },
    clearErrors() {
      self.errors.clear();
      self.otherErrors = '';
      self.otpError = '';
    },
    validate() {
      const emailRegex =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (emailRegex.test(self.parentEmail)) {
        self.errors.delete('parentEmail');
      } else {
        this.setErrors({ parentEmail: ['Please enter a valid email!'] });
      }

      const phoneRegex = /^(\+?91|0)?[6789]\d{9}$/;
      if (self.phone === '') {
        this.setErrors({ phone: ['Please enter a valid phone number!'] });
      } else if (
        (self.phone.startsWith('91') || self.phone.startsWith('+91')) &&
        !phoneRegex.test(self.phone)
      ) {
        this.setErrors({ phone: ['Please enter a valid phone number!'] });
      } else {
        self.errors.delete('phone');
      }
    },
    setParentName(parentName: string) {
      self.errors.delete('parentName');
      self.parentName = parentName;
    },
    setParentEmail(parentEmail: string) {
      self.errors.delete('parentEmail');
      self.parentEmail = parentEmail;
    },
    setPhone(phone: string) {
      self.errors.delete('phone');
      self.phone = phone;
    },
    setKidName(kidName: string) {
      self.errors.delete('kidName');
      self.kidName = kidName;
    },
    setKidSchool(kidSchool: string) {
      self.errors.delete('kidSchool');
      self.kidSchool = kidSchool;
    },
    setKidGrade(kidGrade: string) {
      self.errors.delete('grade');
      self.kidGrade = kidGrade;
    },
    setExtraInfo(params: { [key: string]: string }) {
      self.extraInfo.merge(params);
    },
    setHasLaptop(option: boolean) {
      self.hasLaptop = option;
    },
    setOtp(otp: string) {
      self.errors.delete('otp');
      self.otp = otp;
    },
    setGuestUsername(username: string) {
      self.guestUsername = username;
    },
    signup: flow(function* (schoolRequired = true) {
      self.loading = true;
      self.errors.clear();
      let body = {
        parentName: self.parentName,
        parentEmail: self.parentEmail,
        phone: self.phone,
        kidName: self.kidName,
        kidSchool: self.kidSchool,
        grade: self.kidGrade,
        extraInfo: self.extraInfo,
        otp: self.otp,
        username: self.guestUsername
      };

      if (!schoolRequired) {
        delete body['kidSchool'];
      }
      try {
        const response: ApiResponse<any> = yield signup(body);
        if (response.problem) {
          if (response.status === 400) {
            const { otp, ...otherErrors } = response.data;
            self.errors.merge(otherErrors);
            self.otpError = (otp || '').toString();
          } else {
            self.otherErrors = SERVER_ERROR_MESSAGE;
          }
          return;
        }
        if (response.status === 201) {
          self.signupSuccess = true;
          const { data } = response;
          getEnv(self).commonStore.setToken(data.auth_token);
        }
      } finally {
        self.loading = false;
      }
    }),
    pre_validate: flow(function* (
      schoolRequired = true,
      allowExistingPhone = true
    ) {
      self.loading = true;
      self.errors.clear();
      let body = {
        parentName: self.parentName,
        parentEmail: self.parentEmail,
        phone: self.phone,
        kidName: self.kidName,
        kidSchool: self.kidSchool,
        grade: self.kidGrade,
        extraInfo: self.extraInfo,
        otp: self.otp,
        username: self.guestUsername
      };

      if (!schoolRequired) {
        delete body['kidSchool'];
      }
      try {
        const response: ApiResponse<any> = yield pre_validate(body);
        if (response.problem) {
          if (response.status === 400) {
            if (allowExistingPhone) {
              // Ignore if phone already exists error is received
              if (response.data.length === 1 && response.data.get('phone'))
                return;
            }
            self.errors.merge(response.data);
          } else {
            self.errors.merge({
              error: [SERVER_ERROR_MESSAGE]
            });
          }
          return;
        }
      } finally {
        self.loading = false;
      }
    }),
    postOtp: flow(function* () {
      self.loading = true;
      self.errors.clear();
      try {
        const response: ApiResponse<any> = yield signupOtp({
          parentName: self.parentName,
          parentEmail: self.parentEmail,
          phone: self.phone,
          kidName: self.kidName,
          kidSchool: self.kidSchool,
          grade: self.kidGrade,
          extraInfo: self.extraInfo
        });
        if (response.problem) {
          if (response.status === 400) {
            self.errors.merge(response.data);
          } else {
            self.errors.merge({
              error: [SERVER_ERROR_MESSAGE]
            });
          }
          return;
        }
        return response;
      } finally {
        self.loading = false;
      }
    }),
    generateOtp: flow(function* () {
      self.errors.clear();
      try {
        const response: ApiResponse<any> = yield postOtp({
          phone: self.phone
        });
        if (response.problem) {
          if (response.status === 403) {
            self.errors.merge({ phone: 'Phone number is not registered' });
          }
          return response;
        }
        return response;
      } finally {
      }
    }),
    guestLogin: flow(function* () {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield guestLogin({
          name: self.kidName,
          grade: self.kidGrade
        });
        if (response.problem) {
          if (response.status === 400) {
            self.errors.merge(response.data);
          }
        }
        if (response.status === 201) {
          self.username = response.data.username;
          getEnv(self).commonStore.setToken(response.data.auth_token);
        }
        return response;
      } finally {
        self.loading = false;
      }
    }),
    phoneExistsForLDSession: flow(function* (referrer?: IUser) {
      self.loading = true;
      self.errors.clear();
      try {
        const response: ApiResponse<any> = yield phoneExists(self.phone);
        if (response.problem) {
          if (response.status === 400 || response.status === 404) {
            self.errors.merge({ phone: [response.data.error] });
          } else {
            self.errors.merge({ phone: ['Ops! something went wrong.'] });
          }
        } else if (response.status === 200) {
          if (referrer && response.data.phone_exists) {
            self.errors.merge({
              phone: [
                `This number belongs to an existing student. Please ask ${referrer.first_name} to invite you by adding the phone number “${self.phone}”`
              ]
            });
          }
          self.existingStudentName = response.data.student_name;
        }
      } finally {
        self.loading = false;
      }
    }),
    createGuestAccount: flow(function* (
      phone: string,
      kidName: string,
      grade: string,
      source?: string
    ) {
      self.errors.clear();
      const response: ApiResponse<any> = yield createGuestAccount(
        phone,
        kidName,
        grade,
        source,
        self.extraInfo
      );
      if (response.problem) {
        if (response.status === 400) {
          self.errors = response.data;
        }
      } else {
        self.guestUsername = response.data.username;
        self.guestAccountCreated = true;
      }
    })
  }))
  .views((self) => ({
    get isClean() {
      return self.otherErrors === '' && self.errors.size === 0;
    },
    get hasValidPhone() {
      if (self.phone === '') return false;
      if (
        (self.phone.startsWith('91') || self.phone.startsWith('+91')) &&
        !PHONE_REGEX_INDIA.test(self.phone)
      )
        return false;
      return self.phone.length > 6;
    }
  }));

export default SignUpStore;

export interface ISignUpStore extends Instance<typeof SignUpStore> {}
