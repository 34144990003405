import { fetchCoachInsightCallStats } from "api/onboarding";
import { ApiResponse } from "apisauce";
import { cast, flow, types } from 'mobx-state-tree';
import User from './User';


const CoachInsightCallStats = types.model('CoachInsightCallStats', {
  avg_rating: types.maybeNull(types.number),
  sessions_attended_by_student: types.number,
  pic_calls_completed_count: types.number,
  coach: types.maybe(User),
  student: types.maybe(User),
});


const InsightCallStore = types.model('InsightCallStore', {
    loading: false,
    coachInsightCallStats: types.maybe(CoachInsightCallStats),
    error: types.maybe(types.maybeNull(types.string))
  })
  .actions((self) => ({
    fetchCoachInsightCallStats: flow(function* (batch_student_name: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchCoachInsightCallStats(batch_student_name);
        if (response.problem) {
          throw response;
        }
        if (response.status === 200) {
          self.coachInsightCallStats = cast(response.data);
        }
      } finally {
        self.loading = false;
      }
    }),
}));

export default InsightCallStore;