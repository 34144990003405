import {
  createRazorpayConfig,
  fetchPartialPaymentData,
  fetchSubscriptionPlanInfo,
  fetchSubscriptionPlanOption,
  fetchSubscriptionPlans,
  IFetchPartialPaymentData,
  IValidateOrder,
  IValidatePaymentLink,
  validateOrderPayment,
  validatePaymentLink
} from 'api/payments';
import { ApiResponse } from 'apisauce';
import { cast, flow, types } from 'mobx-state-tree';
import { roundOff } from 'utils/math';

const SubscriptionPlan = types
  .model('SubscriptionPlan', {
    name: types.string,
    amount: types.number,
    discount: types.number,
    display_page_config: types.maybeNull(types.map(types.boolean)),
    duration: types.number,
    is_admission_fee_waived: types.boolean
  })
  .views((self) => ({
    get bestValue() {
      return self.display_page_config?.get('bestValue');
    },
    get monthlyFees() {
      return roundOff((self.amount - self.discount) / self.duration, 2);
    },
    get discountedPrice() {
      return self.amount - self.discount;
    }
  }));

const Subscription = types.model('Subscription', {
  name: types.string,
  title: types.string,
  description: types.string,
  term_amount: types.number,
  model: types.number,
  subject: types.string,
  admission_fee: types.number,
  subscription_plans: types.array(SubscriptionPlan)
});

const UserSubscription = types.model('UserSubscription', {
  name: types.string,
  subscription_name: types.string,
  expires_in_days: types.number,
  cut_off_in_days: types.maybeNull(types.number),
  end_date: types.string
});

const PaymentStore = types
  .model('PaymentStore', {
    name: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    campaignType: types.maybeNull(types.string),
    loading: false,
    apiSuccess: false,
    installmentPayment: false,
    errors: types.map(types.array(types.string)),
    selectedPlan: '',
    subscription: types.maybeNull(Subscription),
    subscriptionPlanOptions: types.array(SubscriptionPlan),
    user_subscription: types.maybe(UserSubscription)
  })
  .actions((self) => ({
    setLoading(val: boolean) {
      self.loading = val;
    },
    validate() {
      const phoneRegex = /^(\+?91|0)[6789]\d{9}$/;
      if (self.name === '') {
        this.setErrors({ name: 'This field is required' });
      }
      if (self.phone === '') {
        this.setErrors({ phone: 'This field is required' });
      } else if (
        (self.phone.startsWith('91') || self.phone.startsWith('+91')) &&
        !phoneRegex.test(self.phone)
      ) {
        this.setErrors({ phone: ['Please enter a valid phone number!'] });
      } else {
        self.errors.delete('phone');
      }
    },
    setName(name: string) {
      self.errors.delete('name');
      self.name = name;
    },
    setPhone(phone: string) {
      self.errors.delete('phone');
      self.phone = phone;
    },
    setEmail(email: string) {
      self.errors.delete('email');
      self.email = email;
    },
    setCampaignType(campaignType: string) {
      self.campaignType = campaignType;
    },
    setInstallmentPayment(installmentPayment: boolean) {
      self.installmentPayment = installmentPayment;
    },
    setSelectedPlan(plan: string) {
      self.selectedPlan = plan;
    },
    createRZPaymentConfig: flow(function* (data) {
      self.loading = true;
      self.apiSuccess = false;
      self.errors.clear();
      try {
        const response: ApiResponse<any> = yield createRazorpayConfig(data);
        if (response.problem) {
          if (response.problem === 'NETWORK_ERROR') {
            self.errors.merge({ error: ['Network Error!! Try again later'] });
          }
          throw response.originalError;
        }
        if (response.status === 200) {
          self.apiSuccess = true;
          return response.data;
        }
      } finally {
        self.loading = false;
        self.apiSuccess = false;
      }
    }),
    validateOrderPayment: flow(function* (data: IValidateOrder) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield validateOrderPayment(data);
        if (response.problem) {
          if (response.problem === 'NETWORK_ERROR') {
            self.errors.merge({ error: ['Network Error!! Try again later'] });
          }
          throw response.originalError;
        }
        if (response.status === 200) {
          return response.data;
        }
      } finally {
        self.loading = false;
      }
    }),
    fetchPartialPaymentData: flow(function* (data: IFetchPartialPaymentData) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchPartialPaymentData(data);
        if (response.problem) {
          if (response.problem === 'NETWORK_ERROR') {
            self.errors.merge({ error: ['Network Error!! Try again later'] });
          }
          throw response.originalError;
        }
        if (response.status === 200) {
          return response.data;
        }
      } finally {
        self.loading = false;
      }
    }),
    validatePaymentLink: flow(function* (data: IValidatePaymentLink) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield validatePaymentLink(data);
        if (response.status === 200) {
          if (response.data) return response.data.redirect_url;
        }
        if (response.problem) {
          return;
        }
      } finally {
        self.loading = false;
      }
    }),
    fetchSubscriptionPlanOption: flow(function* (subscriptionPlanName: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchSubscriptionPlanOption(
          subscriptionPlanName
        );
        if (response.status === 200) {
          self.subscriptionPlanOptions = cast([response.data]);
        }
        if (response.problem) {
          return;
        }
      } finally {
        self.loading = false;
      }
    }),
    fetchSubscriptionPlans: flow(function* (
      subscriptionName: string,
      planOption?: string
    ) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchSubscriptionPlans(
          subscriptionName,
          planOption
        );
        if (response.status === 200) {
          self.subscription = cast(response.data);
        }
        if (response.problem) {
          return;
        }
      } finally {
        self.loading = false;
      }
    }),
    subscriptionPlanInfo: flow(function* (
      subscriptionName: string,
      username: string
    ) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchSubscriptionPlanInfo(
          subscriptionName,
          username
        );
        if (response.status === 200) {
          self.user_subscription = cast(response.data?.plan_info);
        }
        if (response.problem) {
          return;
        }
      } finally {
        self.loading = false;
      }
    })
  }));

export default PaymentStore;
