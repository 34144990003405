import mixpanel from 'mixpanel-browser';

var startTime: Date = new Date();

export const handleMPPageVisit = ({
  router,
  pageName
}: {
  router?: any;
  pageName?: string;
}) => {
  startTime = new Date();
  if (router?.components[router.pathname].props.pageProps.meta) {
    pageName =
      router.components[router.pathname].props.pageProps.meta.mp_page_name ||
      null;
  }
  mixpanel.track('Page Visit', {
    'Page Name': pageName || null,
    'Page Variant': null
  });
};

export const handleMPEnrollNowClick = (id: string) => {
  var endTime: Date = new Date();
  mixpanel.track('Enrol Now Click', {
    'Button Index': id,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleMPWhatsAppClick = (id: string) => {
  var endTime = new Date();
  mixpanel.track('WhatsApp button click', {
    'Button Index': id,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleDetailFormSubmitted = (formType = 'post-visit') => {
  var endTime = new Date();
  mixpanel.track('Details Form Submitted', {
    'Form Type': formType,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleDetailFormDismissed = () => {
  var endTime = new Date();
  mixpanel.track('Details Form Dismissed', {
    // "Button Index": id,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleHomeButtonClick = () => {
  var endTime = new Date();
  mixpanel.track('Homepage Button Click', {
    // "Button Index": id,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleYouTubeClick = (id: string) => {
  var endTime = new Date();
  mixpanel.track('YouTube Click', {
    'Button Index': id,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleVideoPlayEvent = (id: string) => {
  var endTime = new Date();
  mixpanel.track('Video Play', {
    'Video Name': id,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleApplyNowEvent = (id: string) => {
  var endTime = new Date();
  mixpanel.track('Apply Now', {
    'Button Index': id,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleDownloadCurriculumEvent = (id: string) => {
  var endTime = new Date();
  mixpanel.track('Download Curriculum', {
    'Button Index': id,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleMasterClassClickEvent = (id: string) => {
  var endTime = new Date();
  mixpanel.track('Masterclass Redirect', {
    'Button Index': id,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleRazorpayModalEvent = () => {
  var endTime = new Date();
  mixpanel.track('Razorpay Modal Open', {
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleRazorpayPaymentEvent = (data: { [key: string]: string }) => {
  var endTime = new Date();
  mixpanel.track('Razorpay Payment', {
    'Payment ID': data.paymentID,
    'Order ID': data.orderID,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleOrientationPageViewedEvent = (
  name: string,
  phone: string,
  userVerified: boolean,
  inviteExpired: boolean,
  referralCode?: string
) => {
  var endTime = new Date();
  mixpanel.track('Orientation Page Viewed', {
    'Student Name': name,
    'Phone Number': phone,
    'User Verified': userVerified,
    'Referral Code': referralCode,
    // 'Was Sent Booking Reminder': bookingReminderSent,
    'Invite Expired': inviteExpired,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleOrientationSlotSelectedEvent = (date, time) => {
  var endTime = new Date();
  mixpanel.track('Orientation Slot Selected', {
    'Date Chosen': date,
    'Time Chosen': time,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleOTPSuccessEvent = (userVerified: boolean) => {
  var endTime = new Date();
  mixpanel.track('OTP Success', {
    'User Verified': userVerified,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};

export const handleOrientationBookedEvent = (coachName: string, date: string, time: string, sessionId: string) => {
  var endTime = new Date();
  mixpanel.track('Orientation Booked', {
    'Coach Name': coachName,
    'Date': date,
    'Time': time,
    'Session Id': sessionId,
    'Time Spent': (endTime.getTime() - startTime.getTime()) / 1000
  });
};
