import {
  allotBatch,
  bookASlot,
  fetchBookedSlot,
  fetchCoachInfo,
  fetchFreeSlots,
  fetchFreeSlotsForOnDemandBooking,
  bookOnDemandInsightCall,
  fetchOnboardingBatches,
  IAllotBatches,
  IGetBatches,
  rescheduleInsightCallSlot
} from 'api/onboarding';
import { ApiResponse } from 'apisauce';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import User, { IUser } from './User';

const Slot = types.model('Slot', {
  start: types.string,
  end: types.string
});

const FreeSlots = types.model('FreeSlots', {
  slot: types.map(Slot)
});

const AllotedBatchSlot = types.model('AllotedBatchSlot', {
  id: types.number,
  start_at: types.string,
  timing_descriptions: types.array(types.string)
});

const OnboardingStore = types
  .model('OnboardingStore', {
    loading: false,
    freeSlots: types.array(FreeSlots),
    coach: types.maybe(User),
    allotedBatchSlot: types.maybe(AllotedBatchSlot),
    parentQuery: types.maybe(types.maybeNull(types.string)),
    isParent: types.maybe(types.maybeNull(types.boolean)),
  })
  .actions((self) => ({
    setCoach: (coach: IUser) => {
      self.coach = cast(coach);
    },
    setAllotedBatch: (batch: IAllotedBatchSlot) => {
      self.allotedBatchSlot = cast(batch);
    },
    fetchFreeSlots: flow(function* (identifier: string, reschedule = false) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchFreeSlots(
          identifier,
          reschedule
        );
        if (response.problem) {
          throw response;
        }
        if (response.status === 200) {
          // self.freeSlots = response.data;
          // this.setFreeSlot(response.data);
          return response.data;
        }
      } finally {
        self.loading = false;
      }
    }),
    setParentQuery(parentQuery) {
      self.parentQuery = parentQuery;
    },
    setIsParent(isParent) {
      self.isParent = isParent;
    },
    fetchFreeSlotsForOnDemandBooking: flow(function* (batch_student_name: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchFreeSlotsForOnDemandBooking(
          batch_student_name
        );
        if (response.problem) {
          throw response;
        }
        if (response.status === 200) {
          return response.data;
        }
      } finally {
        self.loading = false;
      }
    }),
    bookASlot: flow(function* (
      identifier: string,
      slot: { [key: string]: string }
    ) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield bookASlot(identifier, slot);
        if (response.problem) {
          throw response;
        }
        if (response.status === 200) {
          return response.data;
        }
      } finally {
        self.loading = false;
      }
    }),
    rescheduleInsightCallSlot: flow(function* (
      identifier: string,
      slot: { [key: string]: string }
    ) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield rescheduleInsightCallSlot(
          identifier,
          slot
        );
        if (response.problem) {
          throw response;
        }
        if (response.status === 200) {
          return response.data;
        }
      } finally {
        self.loading = false;
      }
    }),
    bookOnDemandInsightCall: flow(function* (
      batch_student_name: string,
      slot: { [key: string]: string }
    ) {
      if (self.isParent === undefined || self.isParent === null) {
        console.error('isParent value is not defined');
        return;
      }
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield bookOnDemandInsightCall(
          batch_student_name,
          slot,
          self.isParent,
          self.parentQuery,
        );
        if (response.problem) {
          throw response;
        }
        if (response.status === 200) {
          return response;
        }
      } finally {
        self.loading = false;
      }
    }),
    getBookedSlot: flow(function* (identifier: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchBookedSlot(identifier);
        if (response.problem) {
          throw response;
        }
        if (response.status === 200) {
          return response.data;
        }
      } finally {
        self.loading = false;
      }
    }),
    getCoachInfo: flow(function* (identifier: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchCoachInfo(identifier);
        if (response.problem) {
          throw response;
        }
        if (response.status === 200) {
          return response.data;
        }
      } finally {
        self.loading = false;
      }
    }),
    getOnboardingBatches: flow(function* (params: IGetBatches) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchOnboardingBatches(params);
        if (response.problem) {
          throw response.originalError;
        }
        if (response.status === 200) {
          return response.data;
        }
      } finally {
        self.loading = false;
      }
    }),
    allotBatch: flow(function* (data: IAllotBatches) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield allotBatch(data);
        if (response.problem) {
          throw response;
        }
        if (response.status === 200) {
          return response.data;
        }
      } finally {
        self.loading = false;
      }
    })
  }));

export default OnboardingStore;
export interface IOnboardingStore extends Instance<typeof OnboardingStore> {}
export interface IAllotedBatchSlot extends Instance<typeof AllotedBatchSlot> {}
