import { create, ApiResponse } from 'apisauce';
import { authStore, commonStore } from '../stores';
import config from '../config';


const api = create({
    baseURL: config.API_BASE_URL,
    timeout: 30000,
});

api.addRequestTransform((request) => {
    if (request.url !== `auth/token/login/`) {
        if (commonStore.token) {
            request.headers['Authorization'] = `Token ${commonStore.token}`;
        }
        const impersonateUser = window.localStorage.getItem('impersonate');
        if (impersonateUser) request.headers['x-impersonate-user'] = impersonateUser;
    }
});

api.addResponseTransform((response) => {
    if (response.status === 401) {
        authStore.logout();
    }
});

interface SignupProps {
    parentName: string;
    parentEmail: string;
    phone: string;
    kidName: string;
    kidSchool?: string;
    grade: string;
    extraInfo: Map<string, string>;
    otp: string;
    username?: string;
}

export const signup = ({ parentName, parentEmail, phone, kidName, kidSchool, grade, extraInfo, otp, username }: SignupProps) =>
    api.post(`auth/student/signup/`, { parentName, parentEmail, phone, kidName, kidSchool, grade, extraInfo, otp, username });

export const pre_validate = ({ parentName, parentEmail, phone, kidName, kidSchool, grade, extraInfo, otp, username }: SignupProps) =>
    api.post(`auth/student/prevalidate/`, { parentName, parentEmail, phone, kidName, kidSchool, grade, extraInfo, otp, username });

interface OtpProps {
    phone: string;
    trials?: number;
    login_intent?: boolean;
}

interface LoginProps {
    username: string;
    password: string;
    token?: string;
}

export const login = ({ username, password, token }: LoginProps) =>
    api.post(`auth/token/login/`, { username, password, token });

export const postOtp = ({ phone }: OtpProps) => api.post(`users/otp/request`, { phone });

export const guestLogin = ({ name, grade }: { name: string; grade: string }) =>
    api.post('users/guest/login/', { name, grade });

export const getCurrentUser = () => api.get(`users/me/`);

interface UserProps {
    name: string;
}

export const saveCurrentUser = ({ name }: UserProps) => api.patch(`users/me/`, { name });

interface SignupOtpProps {
    parentName: string;
    parentEmail: string;
    phone: string;
    kidName: string;
    kidSchool: string;
    grade: string;
    extraInfo: Map<string, string>;
}
export const signupOtp = ({ parentName, parentEmail, phone, kidName, kidSchool, grade, extraInfo }: SignupOtpProps) =>
    api.post(`auth/student/request_otp/`, { parentName, parentEmail, phone, kidName, kidSchool, grade, extraInfo });


export const fetchOpenMasterClassSession = () => api.get('masterclass_open/');

export const masterClassRegister = ({ sessionName }: { sessionName: string }) =>
    api.post(`masterclass/${sessionName}/register/`, { session_name: sessionName });


export const createLandingLeads = (data: ILandingLeads) => api.post('zoho/landing-leads', data);

interface ILandingLeads {
    student_name: string;
    parent_name: string;
    phone: string;
    grade: string;
    email?: string;
    tracking_params: Map<string, string>;
    page_type: string,
}

export const getSchoolDetails = (schoolName: string) =>
    api.get(`/mc-school/${schoolName}/`);

export const getStudentDetails = (username: string) => api.get('payments/student-details', { username });
export const validateCouponCode = (couponCode: string) => api.post('payments/validate-discount-coupon', { coupon_code: couponCode });

export const fetchFreeSlotsForLDSession = () => api.get('my/ld-sessions/available-slots/');
export const bookLDSession = (start: string, end: string) => api.post('my/ld-sessions/', { slot: { start: start, end: end }});
export const phoneExists = (phone: string) => api.post('my/ld-sessions/phone-exists/', { phone: phone });
export const fetchUserReferralDetails = (referral_code: string) => api.get(`referrals/${referral_code}/details/`);
export const referralPageVisited = (username: string, source: string) => api.put(`guest-status/${username}/page-visited/`, { source: source });
export const referralPhoneValidated = (username: string) => api.put(`guest-status/${username}/phone-validated/`);
export const fetchLDSessionDetails = (session_name: string) => api.get(`my/ld-sessions/${session_name}/`);
export const createGuestAccount  = (
  phone: string, kidName: string, grade: string, source?: string, extraInfo?: Map<string, string>
) => api.post(`guest/`, { phone, kidName, grade, source, extraInfo});
export const rescheduleLDSession = (sessionName: string, start: string, end: string) =>
  api.post(`ld-sessions/${sessionName}/reschedule/`, { slot: { start: start, end: end }});

export const fetchFreeSlotsForOrientationWorkshop = (
  name ?: string, phone ?: string
) => api.get('orientation-workshops/upcoming/', { name, phone });
export const bookOrientationWorkshop = (startAt: string) => api.post('orientation-workshops/book/', { startAt: startAt});
export const fetchOrientationWorkshopDetails = (session_name: string) => api.get(`orientation-workshops/${session_name}/`);

export default api;
