import prodConfig from './prod';
import devConfig from './dev';
import testConfig from './test';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export interface Config {
  readonly REACTION_TIMER: number;
  readonly SCREEN_SHARE_FPS: number;
  readonly MOUSE_SHARE_FPS: number;
  readonly SENTRY_DSN?: string;
  readonly SOCKET_BASE_URL: string;
  readonly API_BASE_URL: string;
  readonly ENABLE_TWILIO: boolean;
  readonly MIN_CHROME_BROWSER: number;
  readonly VERSION: string;
  readonly COMMIT: string;
  readonly DEBUG: boolean;
  readonly APP_ENV: string;
  readonly GOOGLE_OAUTH_CLIENT_ID: string;
  readonly WHATSAPP_NUMBER: string;
  readonly SUPPORT_PHONE_NUMBER: string;
  readonly SUPPORT_EMAIL: string;
  readonly GTM_ID?: string;
  readonly GRADES: Array<{ label: string; value: string }>;
  readonly YOUTUBE_LINK: string;
  readonly WHATSAPP_LINK: string;
  readonly LINKEDIN_LINK: string;
  readonly FACEBOOK_LINK: string;
  readonly MOBILE_WIDTH_BREAKPOINT: string;
  readonly MASTERCLASS_URL: string;
  readonly CODING_CURRICULUM: string;
  readonly SCIENCE_CURRICULUM: string;
  readonly CSF_LEVEL_CURRICULUM: string;
  readonly LOGO: string;
  readonly FB_PIXEL_ID: string;
}

const defaultConfig: Config = {
  REACTION_TIMER: 2,
  SCREEN_SHARE_FPS: 12,
  MOUSE_SHARE_FPS: 18,
  ENABLE_TWILIO: false,
  API_BASE_URL: '',
  SOCKET_BASE_URL: '',
  MIN_CHROME_BROWSER: 80,
  VERSION: 'unknown',
  COMMIT: 'unknown',
  DEBUG: false,
  APP_ENV: 'unknown',
  GOOGLE_OAUTH_CLIENT_ID: '',
  WHATSAPP_NUMBER: '+91-8282824825',
  SUPPORT_PHONE_NUMBER: '8282824825',
  SUPPORT_EMAIL: 'support@stayq.com',
  GRADES: [
    { label: '3rd', value: '3' },
    { label: '4th', value: '4' },
    { label: '5th', value: '5' },
    { label: '6th', value: '6' },
    { label: '7th', value: '7' },
    { label: '8th', value: '8' },
    { label: '9th', value: '9' },
    { label: 'Others', value: 'others' }
  ],
  YOUTUBE_LINK: 'https://www.youtube.com/user/razorrow',
  WHATSAPP_LINK: 'https://l.stayq.us/wa',
  LINKEDIN_LINK: 'https://www.linkedin.com/company/stayqrious',
  FACEBOOK_LINK: 'https://www.facebook.com/stayqrious',
  MOBILE_WIDTH_BREAKPOINT: '760',
  MASTERCLASS_URL: 'https://app.stayqrious.com/masterclass',
  CODING_CURRICULUM:
    'https://dmaa7docgq9sl.cloudfront.net/assets/2021/9/16/AcbDHnda/stayqrious_foundations.pdf',
  SCIENCE_CURRICULUM:
    'https://dmaa7docgq9sl.cloudfront.net/assets/2021/7/9/rXZ26mHi/science-brochure.pdf',
  CSF_LEVEL_CURRICULUM:
    'https://img.stayqrious.com/website/2022/11/30/XQM5OXIa/cs-pt-curriculum-brochure-final.pdf',
  LOGO: 'https://img.stayqrious.com/logo-with-tagline.png',
  FB_PIXEL_ID: '266168667715518'
};

var config: Config = defaultConfig;

if (process.env.NODE_ENV === 'production') {
  config = {
    ...defaultConfig,
    ...(publicRuntimeConfig.REACT_APP_ENV === 'test' ? testConfig : prodConfig),
    VERSION: process.env.REACT_APP_RELEASE_VERSION || 'untagged',
    COMMIT: process.env.REACT_APP_RELEASE_COMMIT || 'untagged',
    APP_ENV: process.env.REACT_APP_ENV || 'production'
  };
} else {
  config = {
    ...defaultConfig,
    ...devConfig,
    VERSION: 'development',
    COMMIT: 'development',
    APP_ENV: 'development',
    DEBUG: true
  };
}

export default config;
