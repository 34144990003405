import api from 'api';

export interface RazorpayPaymentForm {
  name: string;
  phone: string;
  email?: string;
  campaign_type: string;
}

export interface IValidateOrder {
  order_id?: string;
  student?: string;
}

export const createRazorpayConfig = (data: RazorpayPaymentForm) =>
  api.post(`payments/razorpay/config`, data);

export const validateOrderPayment = (data: IValidateOrder) =>
  api.post(`payments/validate-order`, data);

export interface IFetchPartialPaymentData {
  invoice?: string;
  username?: string;
  product?: string;
}

export const fetchPartialPaymentData = (data: IFetchPartialPaymentData) =>
  api.get('payments/fetch-partial-pay-data', data);

export interface IValidatePaymentLink {
  campaign?: string;
  student_username?: string;
}

export const validatePaymentLink = (data: IValidatePaymentLink) =>
  api.get(`payments/validate-payment-link`, data);

export const fetchSubscriptionPlans = (
  subscriptionName: string,
  planOption?: string
) => api.get(`subscriptions/${subscriptionName}/`, { planOption });

export const fetchSubscriptionPlanOption = (subscriptionPlanName: string) =>
  api.get(`subscriptions/plan/${subscriptionPlanName}/`);

export const fetchSubscriptionPlanInfo = (
  subscription: string,
  username: string
) =>
  api.get('subscriptions/user/plan/', {
    subscription,
    username
  });
