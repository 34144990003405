import {
  prevalidateGoodiesForm,
  submitBatchSelectionQuiz,
  submitGoodiesForm
} from 'api/onboarding';
import { ApiResponse } from 'apisauce';
import { toJS } from 'mobx';
import { flow, types, cast, Instance, getEnv } from 'mobx-state-tree';

const TextAnswer = types
  .model('TextAnswer', {
    text: types.string
  })
  .actions((self) => ({
    setText: (text: string) => {
      self.text = text;
    }
  }))
  .views((self) => ({
    get value() {
      return self.text;
    }
  }));

const MultipleChoiceCheckbox = types
  .model('MultipleChoiceCheckbox', {
    choices: types.array(types.string)
  })
  .actions((self) => ({
    selectChoice: (choice: string) => {
      const oldArr = toJS(self.choices);
      oldArr.push(choice);
      self.choices = cast(oldArr);
    },
    deselectChoice: (choice: string) => {
      const oldArr = toJS(self.choices);
      self.choices = cast(oldArr.filter((element) => element !== choice));
    }
  }))
  .views((self) => ({
    isSelected: (choice: string) => {
      return self.choices.includes(choice);
    },
    get value() {
      return self.choices;
    }
  }));

const PaymentQuestion = types
  .model('PaymentQuestion', {
    name: types.identifier,
    questionLabel: types.string,
    required: false,
    choices: types.maybe(types.array(types.string)),
    type: types.union(
      types.literal('radio'),
      types.literal('multiple-choice-checkbox'),
      types.literal('text-area')
    ),
    answer: types.maybe(types.union(TextAnswer, MultipleChoiceCheckbox)),
    error: false
  })
  .actions((self) => ({
    setQuestion: () => {
      switch (self.type) {
        case 'multiple-choice-checkbox':
          self.answer = cast({ choices: [] });
          break;
        default:
          self.answer = cast({ text: '' });
      }
    },
    validateAnswer: () => {
      self.error = false;
      if (!self.required) return true;
      switch (self.type) {
        case 'multiple-choice-checkbox':
          if (self.answer.value.length > 0) return true;
          break;
        default:
          if (self.answer.value) return true;
      }
      self.error = true;
      return false;
    }
  }))
  .views((self) => ({
    toQuestionAnswerObject: () => {
      return { [self.name]: self.answer };
    }
  }));

const goodiesFormDefault = {
  studentName: '',
  gender: '',
  buildingName: '',
  city: '',
  pincode: '',
  tshirtSize: 'none',
  parentName: '',
  parentPhone: '',
  studentPhone: '',
  validated: false
};

const GoodiesForm = types
  .model('GoodiesForm', {
    studentName: '',
    gender: '',
    buildingName: '',
    city: '',
    pincode: '',
    parentName: '',
    parentPhone: '',
    studentPhone: '',
    parentRelationship: '',
    validated: false
  })
  .actions((self) => ({
    setValue: (
      key:
        | 'studentName'
        | 'gender'
        | 'buildingName'
        | 'city'
        | 'pincode'
        | 'parentName'
        | 'parentPhone'
        | 'studentPhone'
        | 'parentRelationship',
      value: string
    ) => {
      self[key] = value;
    }
  }));

const PostPaymentFormStore = types
  .model('PostPaymentFormStore', {
    order_id: '',
    phone: '',
    selectedPage: types.optional(
      types.union(
        types.literal('quizForm'),
        types.literal('batchSelectionPage'),
        types.literal('goodiesForm'),
        types.literal('selectedBatchResult')
      ),
      'batchSelectionPage'
    ),
    questions: types.array(PaymentQuestion),
    goodiesForm: types.maybe(GoodiesForm),
    submittingForm: false,
    validatedGoodiesForm: false,
    validatingForm: false
  })
  .views((self) => ({
    get batchSelectionQuizForm() {
      const result = {};
      self.questions.forEach((question) => {
        result[question.name] = question.answer.value;
      });
      return result;
    }
  }))
  .actions((self) => ({
    setQuestions: (questions: IPaymentQuestion[]) => {
      self.questions = cast(questions);
    },
    setPage: (
      page:
        | 'quizForm'
        | 'batchSelectionPage'
        | 'goodiesForm'
        | 'selectedBatchResult'
    ) => {
      self.selectedPage = page;
    },
    initializeGoodiesForm: () => {
      self.goodiesForm = cast(goodiesFormDefault);
    },
    prevalidateGoodiesForm: flow(function* (
      fieldsToBeValidated: { [key: string]: string },
      errorCallBack: (msg: string) => void
    ) {
      self.validatingForm = true;
      try {
        const response: ApiResponse<any> = yield prevalidateGoodiesForm({
          ...fieldsToBeValidated,
          order_id: self.order_id
        });
        if (response.problem) {
          const fieldKey = Object.keys(fieldsToBeValidated)[0];
          if (response.status == 400 && response.data) {
            if (Array.isArray(response.data)) {
              errorCallBack(response.data[0]);
              return;
            } else if (
              typeof response.data === 'object' &&
              response.data[fieldKey]
            ) {
              errorCallBack(response.data[fieldKey]);
              return;
            }
            errorCallBack('There was a problem validating the field');
          }

          return;
        }
      } finally {
        self.validatingForm = false;
      }
      return true;
    }),
    submitGoodiesForm: flow(function* (errorCallBack: (msg: string) => void) {
      self.submittingForm = true;
      try {
        const response: ApiResponse<any> = yield submitGoodiesForm({
          ...self.goodiesForm,
          order_id: self.order_id
        });
        if (response.problem) {
          if (
            response.status == 400 &&
            response.data &&
            Array.isArray(response.data)
          ) {
            errorCallBack(response.data[0]);
          } else {
            errorCallBack('There was a problem submitting the form');
          }
          return;
        }
        self.selectedPage = 'selectedBatchResult';
      } finally {
        self.submittingForm = false;
      }
    }),
    setOrderID: (order_id: string) => {
      self.order_id = order_id;
    },
    submitBatchSelectionQuiz: flow(function* () {
      self.submittingForm = true;
      try {
        const response: ApiResponse<any> = yield submitBatchSelectionQuiz({
          order_id: self.order_id,
          form_data: self.batchSelectionQuizForm
        });
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        self.selectedPage = 'selectedBatchResult';
      } finally {
        self.submittingForm = false;
      }
    }),
    validAllAnswers: () => {
      for (let i = 0; i < self.questions.length; i++) {
        const question = self.questions[i];
        if (!question.validateAnswer()) {
          const element = document.getElementById(
            `QUIZ_QUESTION_${question.name}`
          );
          element?.scrollIntoView({ block: 'center' });
          return false;
        }
      }
      return true;
    },
    validateGoodiesForm: (numberType: string) => {
      self.validatedGoodiesForm = false;
      const requiredFields = [
        'studentName',
        'gender',
        'buildingName',
        'city',
        'pincode',
        'parentName',
        'parentPhone',
        'parentRelationship'
      ];
      for (let field of requiredFields) {
        if (
          !self.goodiesForm[field] ||
          (field === 'parentPhone' && self.goodiesForm[field].length < 5)
        ) {
          self.validatedGoodiesForm = true;
          const element = document.getElementById(`GOODIES_QUESTION_${field}`);
          element?.scrollIntoView({ block: 'center' });
          return false;
        }
      }

      self.validatedGoodiesForm = true;

      return true;
    },
    setPhone: (phone: string) => {
      self.phone = phone;
    }
  }));

export default PostPaymentFormStore;
export interface IPostPaymentFormStore
  extends Instance<typeof PostPaymentFormStore> {}
export interface IPaymentQuestion extends Instance<typeof PaymentQuestion> {}
export interface ITextAnswer extends Instance<typeof TextAnswer> {}
export interface IMultipleChoiceCheckbox
  extends Instance<typeof MultipleChoiceCheckbox> {}
export interface IGoodiesForm extends Instance<typeof GoodiesForm> {}
