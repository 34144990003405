import api from 'api';

export interface IGetBatches {
  order_id?: string;
  student?: string;
  subject?: string;
  classesPerWeek?: number;
}

export interface IAllotBatches {
  order_id?: string;
  student?: string;
  batch_id: string;
}

export const fetchFreeSlots = (identifier: string, reschedule?: boolean) =>
  api.get(`insight-call/free-slots/${identifier}`, { reschedule });
export const fetchFreeSlotsForOnDemandBooking = (batch_student_name: string) =>
  api.get(`insight-call/batch-students/${batch_student_name}/free-slots`);
export const fetchCoachInsightCallStats = (batch_student_name: string) =>
  api.get(`batch_students/${batch_student_name}/coach-insight-call-stats/`);
export const fetchStudentBatchesDetails = (username: string) =>
  api.get(`student/${username}/get-batch-details/`);
export const bookASlot = (
  identifier: string,
  slot: { [key: string]: string }
) => api.post(`insight-call/book-a-slot/${identifier}`, { slot });
export const rescheduleInsightCallSlot = (
  identifier: string,
  slot: { [key: string]: string }
) => api.post(`insight-call/reschedule-slot/${identifier}`, { slot });
export const bookOnDemandInsightCall = (
  batch_student_name: string,
  slot: { [key: string]: string, },
  isParent: boolean,
  parentQuery?: string
) => api.post(`my/insight-calls/batch-students/${batch_student_name}/book-insight-call/`, { slot, isParent, parentQuery });
export const fetchBookedSlot = (identifier: string) =>
  api.get(`insight-call/booked-slot/${identifier}`);
export const fetchCoachInfo = (identifier: string) =>
  api.get(`insight-call/get-coach-and-student/${identifier}`);
export const fetchOnboardingBatches = (params: IGetBatches) =>
  api.get(`onboarding/get-batches/`, (params = params));
export const allotBatch = (params: IAllotBatches) =>
  api.post(`onboarding/allot-batch/`, (params = params));
export const submitBatchSelectionQuiz = (params: any) =>
  api.post(`onboarding/submit-batch-selection-quiz/`, (params = params));
export const submitGoodiesForm = (params: any) =>
  api.post(`onboarding/submit-goodies-form/`, (params = params));
export const prevalidateGoodiesForm = (params: any) =>
  api.post(`onboarding/prevalidate-goodies-form/`, (params = params));
