import { fetchFreeSlotsForOrientationWorkshop, bookOrientationWorkshop,
  fetchOrientationWorkshopDetails } from 'api';
import { ApiResponse } from "apisauce";
import { cast, flow, types } from 'mobx-state-tree';
import User from './User';
import { OrientationStore } from './LDSessionStore';


const OrientationWorkshop = types.model('OrientationWorkshop', {
  name: types.string,
  start_at: types.string,
  coaches: types.maybe(types.array(User)),
}).views((self) => ({
    get coach() {
      return (self.coaches && self.coaches.length > 0) ? self.coaches[0] : null;
    },
    get startAtDateFormatted() {
      return (new Date(self.start_at)).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' });
    },
    get startAtTimeFormatted() {
      return (new Date(self.start_at)).toLocaleTimeString([], { hour12: true, timeZoneName: 'long', hour: '2-digit', minute: '2-digit', }).substring(0, 8).toLowerCase();
    },
  }));


const OrientationWorkshopStore = OrientationStore.named('OrientationWorkshopStore').props({
    createdSession: types.maybe(OrientationWorkshop),
    sessionDetails: types.maybe(OrientationWorkshop),
  }).actions((self) => ({
    fetchFreeSlots: flow(function* (name ?: string, phone ?: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchFreeSlotsForOrientationWorkshop(name, phone);
        if (response.problem) {
          throw response;
        }
        if (response.status === 200) {
          self.freeSlots = cast(response.data);
        }
      } finally {
        self.loading = false;
      }
    }),
    bookOrientationWorkshop: flow(function* (slot) {
      self.loading = true;
      self.error = undefined;
      try {
        const response: ApiResponse<any> = yield bookOrientationWorkshop(slot.start);
        if (response.problem) {
          if (response.status === 400 || response.status === 404) {
            self.error = response.data.error;
          } else {
            self.error = "Ops! something went wrong.";
          }
        } else if (response.status === 200) {
          self.createdSession = response.data
        }
      } finally {
        self.loading = false;
      }
    }),
    fetchOrientationWorkshopDetails: flow(function* (session_name: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchOrientationWorkshopDetails(session_name);
        if (response.problem) {
          throw response;
        }
        if (response.status === 200) {
          self.sessionDetails = cast(response.data);
        }
      } finally {
        self.loading = false;
      }
    }),
    clearErrors: () => {
      self.error = null;
    },
}));

export default OrientationWorkshopStore;