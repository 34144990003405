import { fetchScholarshipPeers } from 'api/scholarship';
import { ApiResponse } from 'apisauce';
import { flow, types, cast, Instance } from 'mobx-state-tree';

const StudentImage = types.model('StudentImage', {
  name: types.string,
  image_url: types.string
});
const Student = types.model('Student', {
  name: types.string,
  image: types.maybeNull(StudentImage),
  grade: types.string
});

const ScholarshipStore = types
  .model('ScholarshipStore', {
    loading: false,
    peersType: types.maybe(types.string),
    school: types.maybe(types.string),
    students: types.array(Student)
  })
  .actions((self) => ({
    fetchScholarshipPeers: flow(function* (
      username: string,
      campaign: string = 'scholarship'
    ) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchScholarshipPeers(
          username,
          campaign
        );
        if (response.problem) {
          if (response.status === 404) return;
        }
        if (response.status === 200) {
          self.peersType = response.data.type;
          (self.students = cast(response.data.students)),
            (self.school = response.data.school);
        }
      } finally {
        self.loading = false;
      }
    })
  }));

export default ScholarshipStore;

export interface IScholarshipStore extends Instance<typeof ScholarshipStore> {}
