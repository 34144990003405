import { fetchFreeSlotsForLDSession, bookLDSession, fetchLDSessionDetails,
  rescheduleLDSession } from "api";
import { ApiResponse } from "apisauce";
import { cast, flow, Instance, types } from 'mobx-state-tree';
import User, { IUser } from './User';


export interface IDateOption {
    label: string;
    value: string;
}

const Slot = types.model('Slot', {
  start: types.string,
  end: types.string
});
export interface ISlot extends Instance<typeof Slot> {}


export const OrientationStore = types.model('OrientationStore', {
  loading: false,
  freeSlots: types.maybe(types.map(types.array(Slot))),
  error: types.maybe(types.maybeNull(types.string))
}).views((self) => ({
  get freeSlotsDays() {
    let days: IDateOption[] = [];

    if (!self.freeSlots) return days;

    const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    let date = new Date();
    let today = date.toJSON().substring(0, 10);

    date.setDate((new Date()).getDate() + 1);
    let tomorrow = date.toJSON().substring(0, 10);

    Array.from(self.freeSlots.keys()).map((date) => {
      if (self.freeSlots.get(date).length === 0) return;

      if (date === today) {
        days.push({ label: 'Today', value: date });
      } else if (date === tomorrow) {
        days.push({ label: 'Tomorrow', value: date });
      } else {
        days.push({ label: weekday[(new Date(date)).getDay()], value: date });
      }
    });

    return days;
  }
}));


const LDSession = types.model('LDSession', {
  name: types.string,
  start_at: types.string,
  coach: types.maybe(User),
}).views((self) => ({
    get startAtDateFormatted() {
      return (new Date(self.start_at)).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' });
    },
    get startAtTimeFormatted() {
      return (new Date(self.start_at)).toLocaleTimeString([], { hour12: true, timeZoneName: 'long', hour: '2-digit', minute: '2-digit', }).substring(0, 8).toLowerCase();
    },
  }));


const LDSessionStore = OrientationStore.named('LDSessionStore').props({
    createdSession: types.maybe(LDSession),
    sessionDetails: types.maybe(LDSession),
  }).actions((self) => ({
    fetchFreeSlots: flow(function* () {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchFreeSlotsForLDSession();
        if (response.problem) {
          throw response;
        }
        if (response.status === 200) {
          self.freeSlots = cast(response.data);
        }
      } finally {
        self.loading = false;
      }
    }),
    bookSession: flow(function* (slot) {
      self.loading = true;
      self.error = undefined;
      try {
        const response: ApiResponse<any> = yield bookLDSession(slot.start, slot.end);
        if (response.problem) {
          if (response.status === 400 || response.status === 404) {
            self.error = response.data.error;
          } else {
            self.error = "Ops! something went wrong.";
          }
        } else if (response.status === 200) {
          self.createdSession = response.data
        }
      } finally {
        self.loading = false;
      }
    }),
    reschedule: flow(function* (slot) {
      if (!self.sessionDetails.name) {
        self.error = "Session details not found.";
        return false;
      }

      self.loading = true;
      self.error = null;
      try {
        const response: ApiResponse<any> = yield rescheduleLDSession(self.sessionDetails.name, slot.start, slot.end);
        if (response.problem) {
          if (response.status === 400 || response.status === 404) {
            self.error = response.data.error;
          } else {
            self.error = "Ops! something went wrong.";
          }
        } else if (response.status === 200) {
          self.createdSession = response.data
        }
      } finally {
        self.loading = false;
      }

      return !self.error;
    }),
    fetchLDSessionDetails: flow(function* (session_name: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchLDSessionDetails(session_name);
        if (response.problem) {
          throw response;
        }
        if (response.status === 200) {
          self.sessionDetails = cast(response.data);
        }
      } finally {
        self.loading = false;
      }
    }),
    clearErrors: () => {
      self.error = null;
    }
}));

export default LDSessionStore;