import GlobalProps from './GlobalProps';
import CommonStore from './CommonStore';
import SignUpStore from './SignupStore';
import UserStore from './UserStore';
import AuthStore from './AuthStore';
import MasterclassStore from './MasterclassStore';
import LandingStore from './LandingStore';
import PaymentStore from './PaymentStore';
import OnboardingStore from './OnboardingStore';
import ScholarshipStore from './ScholarshipStore';
import PostPaymentFormStore from './PostPaymentFormStore';
import LDSessionStore from './LDSessionStore';
import ReferralStore from './ReferralStore';
import InsightCallStore from './InsightCallStore';
import StudentStore from './StudentStore';
import OrientationWorkshopStore from './OrientationWorkshopStore';

export const globalProps = GlobalProps.create({});
export const userStore = UserStore.create({});
export const commonStore = CommonStore.create({});
export const signUpStore = SignUpStore.create({}, { userStore, commonStore });
export const authStore = AuthStore.create({}, { userStore, commonStore });
export const masterclassStore = MasterclassStore.create({}, { commonStore });
export const landingStore = LandingStore.create({});
export const paymentStore = PaymentStore.create({});
export const onboardingStore = OnboardingStore.create({});
export const scholarshipStore = ScholarshipStore.create({});
export const postPaymentFormStore = PostPaymentFormStore.create(
  {},
  { commonStore }
);
export const ldSessionStore = LDSessionStore.create({});
export const referralStore = ReferralStore.create({});
export const insightCallStore = InsightCallStore.create({});
export const studentStore = StudentStore.create({});
export const orientationWorkshopStore = OrientationWorkshopStore.create({});