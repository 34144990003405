import { fetchUserReferralDetails, referralPageVisited, referralPhoneValidated } from "api";
import { ApiResponse } from "apisauce";
import { cast, flow, types } from 'mobx-state-tree';
import User from './User';


const UserReferralDetails = types.model('UserReferralDetails', {
  name: types.string,
  referrer: User,
  user: User,
  user_grade: types.maybeNull(types.string),
  user_phone: types.maybeNull(types.string),
  expires_in_days: types.number,
}).views((self) => ({
    get isExpired() {
      return (self.expires_in_days <= 0);
    }
}));


const ReferralStore = types.model('ReferralStore', {
    loading: false,
    userReferralDetails: types.maybe(UserReferralDetails),
  })
  .actions((self) => ({
    fetchUserReferralDetails: flow(function* (referral_code: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchUserReferralDetails(referral_code);
        if (response.problem) {
          throw response;
        }
        if (response.status === 200) {
          self.userReferralDetails = cast(response.data);
        }
      } finally {
        self.loading = false;
      }
    }),
    pageVisited: flow(function* (username: string, source?: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield referralPageVisited(username, source);
        if (response.problem) {
          throw response;
        }
      } finally {
        self.loading = false;
      }
    }),
    phoneValidated: flow(function* (username: string) {
      self.loading = true;
      try {
        yield referralPhoneValidated(username);
      } finally {
        self.loading = false;
      }
    }),
}));

export default ReferralStore;