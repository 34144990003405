import { types } from "mobx-state-tree";

const Social = types
    .model('Social', {
        twitter: types.string,
        linkedin: types.string,
        facebook: types.string,
        whatsapp_link: types.string,
        whatsapp_nulnber: types.string,
    })

const GlobalProps = types
    .model('GlobalStore', {
        social: types.map(Social)
    })
    .actions((self) => ({
        setSocial(social) {
            self.social = social;
        }
    }))

export default GlobalProps;