import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';

export let notify;

const Notification = () => {

    const [open, setOpen] = useState(false);
    const [type, setType] = useState<any>('success');
    const [message, setMessage] = useState<string | null>(null);

    const handleNotify = (type: string, message: string) => {
        setType(type);
        setMessage(message);
        setOpen(true);
    }

    notify = {
        success: (message: string) => handleNotify('success', message),
        error: (message: string) => handleNotify('error', message),
        warning: (message: string) => handleNotify('warning', message),
        info: (message: string) => handleNotify('info', message),
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    }

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={4000}
            onClose={handleClose}
            message={message}
        >
            <Alert
                severity={type}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

export default Notification;