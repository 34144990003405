import { createLandingLeads, getStudentDetails, validateCouponCode } from "api";
import { ApiResponse } from "apisauce";
import { notify } from "components/styledComponents/Notification";
import { flow, types } from "mobx-state-tree";

export interface formDataType {
    studentName: string,
    phone: string,
    email?: string,
    couponCode?: string,
    grade: string,
}

const LandingStore = types
    .model('LandingStore', {
        loading: false,
        errors: types.map(types.array(types.string)),
        studentName: types.maybeNull(types.string),
        parentName: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        grade: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        pageType: types.maybeNull(types.string),
        couponCode: types.maybeNull(types.string),
        submitSuccess: false,
        formFilled: false,
        trackingParams: types.map(types.string),
    })
    .actions((self) => ({
        validate() {
            const phoneRegex = /^(\+?91|0)[6789]\d{9}$/;
            const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if (self.phone === '' || !self.phone) {
                this.setErrors({ phone: ['Please enter a valid phone number!'] });
            } else if ((self.phone.startsWith('91') || self.phone.startsWith('+91')) && !phoneRegex.test(self.phone)) {
                this.setErrors({ phone: ['Please enter a valid phone number!'] });
            } else {
                self.errors.delete('phone');
            }
            if (self.grade === '' || !self.grade) {
                this.setErrors({ grade: ['Grade is a required field!'] })
            }
            if (self.email) {
                if (emailRegex.test(self.email)) {
                    self.errors.delete('email');
                } else {
                    this.setErrors({ email: ['Please enter a valid email!'] });
                }
            }
            if (self.studentName.length > 70) {
                this.setErrors({ studentName: ['Name should be less than 70 characters'] })
            }
        },
        setStudentName(studentName: string) {
            self.errors.delete('studentName');
            self.studentName = studentName;
        },
        setGrade(grade: string) {
            self.errors.delete('grade');
            self.grade = grade;
        },
        setPhone(phone: string) {
            self.errors.delete('phone');
            self.phone = phone.startsWith('+') ? phone : '+' + phone;
        },
        setEmail(email: string) {
            self.errors.delete('email');
            self.email = email;
        },
        setCouponCode(code: string) {
            self.errors.delete('coupon');
            self.couponCode = code;
        },
        invalidCouponCode(message: string) {
            self.couponCode = '';
            self.formFilled = false;
            this.setErrors({ 'couponCode': [message] })
        },
        setPageType(pageType: string) {
            self.pageType = pageType;
        },
        setParentName() {
            self.parentName = `${self.studentName}'s parent`;
        },
        setFormFilled(formFilled: boolean) {
            self.formFilled = formFilled;
        },
        getFormData(): formDataType {
            let data: formDataType = {
                studentName: self.studentName,
                phone: self.phone,
                grade: self.grade,
            };
            data['email'] = self.email;
            if (self.couponCode) {
                data['couponCode'] = self.couponCode;
            }
            return data;
        },
        setErrors(errors: { [key: string]: Array<string> }) {
            self.errors.merge(errors);
        },
        clearErrors() {
            self.errors.clear();
        },
        setTrackingParams(params: { [key: string]: string }) {
            self.trackingParams.merge(params)
        },
        resetGrade() {
            self.grade = '';
            self.submitSuccess = false;
        },
        reset() {
            self.loading = false;
            self.studentName = "";
            self.parentName = "";
            self.phone = "";
            self.grade = "";
            self.email = "";
            self.submitSuccess = false;
        },
        landingFormSubmit: flow(function* () {
            self.loading = true;
            self.errors.clear();
            self.submitSuccess = false;
            self.formFilled = false;
            try {
                let data = {
                    student_name: self.studentName,
                    parent_name: self.parentName,
                    phone: self.phone,
                    grade: self.grade,
                    tracking_params: self.trackingParams,
                    page_type: self.pageType,
                };
                if (self.email) {
                    data["email"] = self.email;
                }
                const response: ApiResponse<any> = yield createLandingLeads(data);
                if (response.problem) {
                    if (response.problem === "NETWORK_ERROR") {
                        notify.error("Network Error!! Try again later");
                    }
                    if (response.status === 400) {
                        self.errors.merge(response.data);
                    }
                }
                if (response.status === 200) {
                    self.submitSuccess = true;
                    self.formFilled = true;
                }
            }
            finally {
                self.loading = false;
            }
        }),
        getStudentDetails: flow(function* (username: string) {
            self.loading = true;
            try {
                const response: ApiResponse<any> = yield getStudentDetails(username);
                if (response.problem === "NETWORK_ERROR") {
                    self.errors.merge({ error: ["Network Error!! Try again later"] })
                }
                if (response.status === 200) {
                    return response.data;
                }
                if (response.status === 404) {
                    throw response.originalError;
                }
            }
            finally {
                self.loading = false;
            }
        }),
        validateCouponCode: flow(function* () {
            self.loading = true;
            try {
                const response: ApiResponse<any> = yield validateCouponCode(self.couponCode);
                if (response.problem === "NETWORK_ERROR") {
                    self.errors.merge({ error: ["Network Error!! Try again later"] })
                    throw response.originalError;
                }
                if (response.status === 200) {
                    self.formFilled = false;
                    return response.data;
                }
                if (response.status === 400 || response.status === 404) {
                    self.formFilled = false;
                    self.couponCode = '';
                    self.errors.merge({ 'couponCode': ['Coupon code is invalid'] })
                    throw response.originalError;
                }
            }
            finally {
                self.loading = false;
            }
        })
    }))

export default LandingStore;